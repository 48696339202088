import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import Skeleton from "@mui/material/Skeleton";

import DeleteIcon from "@mui/icons-material/Delete";

import { db, JournalEntry } from "../db";

interface JournalListProps {
  items: JournalEntry[];
}

export const JournalList = (props: JournalListProps) => {
  const { items } = props;
  return (
    <Table stickyHeader size="small">
      <colgroup>
        <col style={{ width: "70%" }} />
        <col style={{ width: "30%" }} />
        <col />
      </colgroup>
      <TableBody>
        {items.map((x) => (
          <TableRow key={x.id}>
            <TableCell>
              <TextField
                hiddenLabel
                variant="standard"
                margin="dense"
                placeholder="Name"
                fullWidth
                value={x.name}
                onChange={(e) => {
                  db.journal.put({
                    id: x.id,
                    name: e.target.value,
                    date: x.date,
                    kcal: x.kcal,
                  });
                }}
                size="small"
              ></TextField>
            </TableCell>
            <TableCell>
              <TextField
                hiddenLabel
                variant="standard"
                margin="dense"
                placeholder="kCal"
                fullWidth
                value={x.kcal}
                onChange={(e) => {
                  db.journal.put({
                    id: x.id,
                    name: x.name,
                    date: x.date,
                    kcal: e.target.value,
                  });
                }}
                inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                size="small"
              ></TextField>
            </TableCell>
            <TableCell>
              <IconButton
                color="error"
                onClick={(e) => {
                  if (x.id !== undefined) {
                    db.journal.delete(x.id);
                  }
                }}
              >
                <DeleteIcon />
              </IconButton>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

// export const JournalListInner =

export const JournalSkeleton = () => {
  return (
    <Table stickyHeader size="small">
      <colgroup>
        <col style={{ width: "70%" }} />
        <col style={{ width: "30%" }} />
        <col />
      </colgroup>
      <TableHead>
        <TableRow>
          <TableCell>Name</TableCell>
          <TableCell>kCal</TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell>
            <Skeleton variant="rectangular" height={29} />
          </TableCell>
          <TableCell>
            <Skeleton variant="rectangular" height={29} />
          </TableCell>
          <TableCell>
            <IconButton color="error">
              <DeleteIcon />
            </IconButton>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <Skeleton variant="rectangular" height={29} />
          </TableCell>
          <TableCell>
            <Skeleton variant="rectangular" height={29} />
          </TableCell>
          <TableCell>
            <IconButton color="error">
              <DeleteIcon />
            </IconButton>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};
