import React from "react";
import "./App.css";

import { createBrowserRouter, RouterProvider } from "react-router-dom";

//theme
import "primereact/resources/themes/lara-light-indigo/theme.css";

//core
import "primereact/resources/primereact.min.css";

//icons
import "primeicons/primeicons.css";
import JournalPage from "./views/Journal";
import AddMealPage from "./views/AddMeal";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

// function menuActions() {
//   return <Button icon="pi pi-trash" outlined rounded severity="danger" />
// }

const router = createBrowserRouter([
  {
    path: "/",
    element: <JournalPage />,
  },
  {
    path: "/add_meal",
    element: <AddMealPage />,
  },
]);

function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <div className="App">
        <RouterProvider router={router} />
      </div>
    </LocalizationProvider>
  );
}

export default App;
