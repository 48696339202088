import React, { useEffect, useState } from "react";

import { Toolbar } from "primereact/toolbar";

import {
  Button,
  Collapse,
  IconButton,
  Table,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";

import { GridColDef } from "@mui/x-data-grid";

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import SwipeableViews from "react-swipeable-views";
import { virtualize } from "react-swipeable-views-utils";

import AddIcon from "@mui/icons-material/Add";
import FastForwardIcon from "@mui/icons-material/FastForward";

import Page from "../components/Page";

import {
  subDays,
  addDays,
  startOfToday,
  isEqual,
  differenceInDays,
  parseISO,
} from "date-fns";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { MobileDatePicker } from "@mui/x-date-pickers";
import { db, JournalEntry } from "../db";
import { useLiveQuery } from "dexie-react-hooks";
import { JournalList } from "../components/JournalList";

const VirtualizeSwipeableViews = virtualize(SwipeableViews);

interface SlideRendererProps {
  key: number;
  index: number;
}

function range(min: number, max: number) {
  const len = max - min + 1;
  const arr = new Array(len);
  for (let i = 0; i < len; i++) {
    arr[i] = min + i;
  }
  return arr;
}

const SlideRenderer =
  (
    currentIndex: number,
    goToToday: () => void,
    day_sub_2: JournalEntry[],
    day_sub_1: JournalEntry[],
    today: JournalEntry[],
    day_add_1: JournalEntry[],
    day_add_2: JournalEntry[]
  ) =>
  ({ key, index }: SlideRendererProps) => {
    // const [items, setItems] = useState<JournalEntry[]>([]);
    // useEffect(() => {
    //   async function fetchData() {
    //     // You can await here
    //     const response = await db.journal.where("date").equals(index).toArray();
    //     setItems(response);
    //   }
    //   fetchData();
    // }, [index]); // Or [] if effect doesn't need props or state
    // const day =
    //   index === currentIndex
    //     ? today
    //     : index === currentIndex + 1
    //     ? yesterday
    //     : tomorrow;
    let day: JournalEntry[] = [];
    if (index - 2 === currentIndex) {
      day = day_sub_2;
    } else if (index - 1 === currentIndex) {
      day = day_sub_1;
    } else if (index === currentIndex) {
      day = today;
    } else if (index + 1 === currentIndex) {
      day = day_add_1;
    } else if (index + 2 === currentIndex) {
      day = day_add_2;
    }
    const total_calories = day
      .map((x) => Number(x.kcal))
      .reduce((x, y) => x + y, 0);
    // return (
    //   // <div key={key} style={{ height: "100%" }}>
    //     {/* {index === 0 ? (
    //       <></>
    //     ) : (
    //       <Button
    //         sx={{ marginTop: "10px" }}
    //         variant="outlined"
    //         size="small"
    //         color="secondary"
    //         startIcon={<FastForwardIcon />}
    //         onClick={() => goToToday()}
    //       >
    //         Today
    //       </Button>
    //     )} */}
    //     {/* <p>
    //       key {key} index {index}
    //     </p> */}
    //     {/* <p>Total Calories: {total_calories}</p> */}
    //     <JournalList items={day}></JournalList>
    //   // </div>
    // );
    return day.length > 0 ? (
      <JournalList key={key} items={day}></JournalList>
    ) : (
      <p key={key}>No entries</p>
    );
  };

function JournalPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [date, setDate] = useState(
    parseISO(searchParams.get("date") || startOfToday().toISOString())
  );
  const [pastToday, setPastToday] = useState<JournalEntry[]>([]);
  // const [flip, setFlip] = useState(true);

  const [currentIndex, setCurrentIndex] = useState(
    differenceInDays(startOfToday(), date)
  );

  const isToday = isEqual(date, startOfToday());
  const navigate = useNavigate();
  const addMeal = () =>
    navigate({
      pathname: "/add_meal",
      search: `?${createSearchParams({ date: date.toISOString() })}`,
    });
  const quickAdd = () => {
    db.journal.add({
      date: date,
      name: "",
      kcal: "",
    });
    // setFlip((x) => !x);
  };

  // const [itemStore, setItemStore] = useState<Map<number, JournalEntry[]>>(
  //   new Map()
  // );

  // const itemStore: Map<number, JournalEntry[]> =
  //   useLiveQuery(() => {
  //     const newMap = new Map();
  //     const currentIndex = differenceInDays(startOfToday(), date);
  //     range(Math.max(currentIndex - 2, 0), currentIndex + 2).map(
  //       async (index) =>
  //         newMap.set(
  //           index,
  //           await db.journal.where("date").equals(index).toArray()
  //         )
  //     );
  //     return newMap;
  //   }) || new Map();

  const day_sub_2 = useLiveQuery(
    () => db.journal.where("date").equals(subDays(date, 2)).toArray(),
    [date],
    []
  );
  const day_sub_1 = useLiveQuery(
    () => db.journal.where("date").equals(subDays(date, 1)).toArray(),
    [date],
    []
  );
  const today = useLiveQuery(
    () =>
      db.journal
        .where("date")
        .equals(date)
        .toArray()
        .then((x) => {
          setCurrentIndex(differenceInDays(startOfToday(), date));
          // setPastToday(today);
          return x;
        }),
    [date],
    []
  );
  const day_add_1 = useLiveQuery(
    () => db.journal.where("date").equals(addDays(date, 1)).toArray(),
    [date],
    []
  );
  const day_add_2 = useLiveQuery(
    () => db.journal.where("date").equals(addDays(date, 1)).toArray(),
    [date],
    []
  );

  // console.log(yesterday, today, tomorrow);

  // useEffect(() => {
  //   async function init() {
  //     const newMap = new Map();
  //     const currentIndex = differenceInDays(startOfToday(), date);
  //     range(Math.max(currentIndex - 2, 0), currentIndex + 2).map(
  //       async (index) =>
  //         newMap.set(
  //           index,
  //           await db.journal
  //             .where("date")
  //             .equals(subDays(startOfToday(), index))
  //             .toArray()
  //         )
  //     );
  //     console.log(newMap);
  //     setItemStore(newMap);
  //   }
  //   init();
  // }, [date, flip]);

  const goBackDay = async () => {
    setDate((prevDate) => subDays(prevDate, 1));
  };
  const goForwardDay = () => {
    setDate((prevDate) => addDays(prevDate, 1));
  };
  const backButton = (
    <IconButton onClick={goBackDay}>
      <ChevronLeftIcon />{" "}
    </IconButton>
  );

  const forwardButton = isToday ? (
    <IconButton size="small" disabled>
      <ChevronRightIcon />{" "}
    </IconButton>
  ) : (
    <IconButton size="small" onClick={goForwardDay}>
      <ChevronRightIcon />{" "}
    </IconButton>
  );

  const datePicker = (
    <MobileDatePicker
      selectedSections={null}
      className="datePicker"
      slotProps={{
        textField: { size: "small" },
      }}
      disableFuture
      value={date}
      onChange={(value) => setDate(value || startOfToday())}
    />
  );

  const clearDb = () => {
    db.delete();
  };

  const total_calories = today
    .map((x) => Number(x.kcal))
    .reduce((x, y) => x + y, 0);

  const header = (
    <>
      <Toolbar
        style={{ padding: "0.5em" }}
        start={backButton}
        center={datePicker}
        end={forwardButton}
      />
      <p style={{ lineHeight: "0.5em" }}>Total Calories: {total_calories}</p>
      <Collapse in={!isToday}>
        <Button
          sx={{ marginTop: "10px" }}
          variant="outlined"
          size="small"
          color="secondary"
          startIcon={<FastForwardIcon />}
          onClick={() => setDate(startOfToday())}
        >
          Today
        </Button>
      </Collapse>
    </>
  );

  // const currentIndex = differenceInDays(startOfToday(), date);

  const content = (
    <>
      <Table stickyHeader size="small">
        <colgroup>
          <col style={{ width: "70%" }} />
          <col style={{ width: "30%" }} />
          <col />
        </colgroup>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>kCal</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
      </Table>
      <VirtualizeSwipeableViews
        style={{ height: "100%" }}
        slideCount={10_000}
        overscanSlideAfter={2}
        overscanSlideBefore={2}
        axis="x-reverse"
        index={differenceInDays(startOfToday(), date)}
        onChangeIndex={(index, last) => {
          setDate(subDays(startOfToday(), index));
          // setPastToday(tomorrow);
        }}
        slideRenderer={SlideRenderer(
          currentIndex,
          () => setDate(startOfToday()),
          day_sub_2,
          day_sub_1,
          today,
          day_add_1,
          day_add_2
        )}
      />
    </>
  );
  const footer = (
    <>
      <Toolbar
        center={
          <div>
            <Button
              variant="outlined"
              startIcon={<AddIcon />}
              onClick={quickAdd}
            >
              New
            </Button>
            <Button
              sx={{ marginLeft: "10px" }}
              variant="outlined"
              color="secondary"
              startIcon={<AddIcon />}
              onClick={addMeal}
            >
              Saved
            </Button>
            {/* <IconButton onClick={clearDb} color="error">
              <DeleteIcon />{" "}
            </IconButton> */}
          </div>
        }
      />
    </>
  );
  return (
    <Page header={header} content={content} footer={footer} title="Journal" />
  );
}

export default JournalPage;
