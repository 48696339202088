interface TitleBarProps {
  title: string;
}

function TitleBar(props: TitleBarProps) {
  const center = <h2>{props.title}</h2>;
  return center;
}

interface PageProps {
  title: string;
  header: JSX.Element;
  content: JSX.Element;
  footer: JSX.Element;
}

function Page(props: PageProps) {
  return (
    <>
      <TitleBar title={props.title}></TitleBar>
      <div className="header">{props.header}</div>
      <div className="content">{props.content}</div>
      <div className="footer">{props.footer}</div>
    </>
  );
}

export default Page;
