// db.ts
import Dexie, { Table } from 'dexie';

export interface Meal {
  id?: number,
  name: string;
  kcal: string;
  notes: string;
}

// export interface EntryItem {
//   name: string,
//   kcal: number,
// }

export interface JournalEntry {
  id?: number,
  date: Date,
  name: string,
  kcal: string,
}

export class MySubClassedDexie extends Dexie {
  // 'friends' is added by dexie when declaring the stores()
  // We just tell the typing system this is the case
  meals!: Table<Meal>; 
  journal!: Table<JournalEntry>; 

  constructor() {
    super('myDatabase');
    this.version(1).stores({
      meals: '++id, name, kcal, notes', // Primary key and indexed props
      journal: '++id, date, name, kcal',
    });
  }
}

export const db = new MySubClassedDexie();

