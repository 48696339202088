import React, { useState } from "react";
import { Toolbar } from "primereact/toolbar";

import {
  Button,
  IconButton,
  InputAdornment,
  OutlinedInput,
  SwipeableDrawer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import CancelIcon from "@mui/icons-material/Cancel";
import DeleteIcon from "@mui/icons-material/Delete";

import { filter } from "fuzzy";

import Page from "../components/Page";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";

import { db, Meal } from "../db";
import { useLiveQuery } from "dexie-react-hooks";

import { parseISO } from "date-fns";

const menuAdd = (addCallback: () => void) => {
  return (
    <IconButton size="small" color="primary" onClick={addCallback}>
      <AddIcon />
    </IconButton>
  );
};

const menuEdit = (editCallback: () => void) => {
  return (
    <IconButton size="small" color="secondary" onClick={editCallback}>
      <EditIcon />
    </IconButton>
  );
};

interface AddMealProps {
  meal: Meal;
  save: (meal: Meal) => void;
  cancel: () => void;
  delete: () => void;
}

const AddMeal = (props: AddMealProps) => {
  const [id, setId] = useState<number | undefined>(undefined);

  // const meal = useLiveQuery(async () => {
  //   return await db.meals.get(props.id || -1);
  // }, []);

  const [name, setName] = useState(props.meal.name);
  const [kcal, setKcal] = useState(props.meal.kcal);
  const [notes, setNotes] = useState(props.meal.notes);

  if (props.meal && props.meal.id && props.meal.id !== id) {
    setName(props.meal.name);
    setKcal(props.meal.kcal);
    setNotes(props.meal.notes);

    setId(props.meal.id);
  }

  // const save = async () => {
  //   await db.meals
  //     .put(
  //       {
  //         name,
  //         kcal,
  //         notes,
  //       },
  //       id
  //     )
  //     .catch((e) => {
  //       console.log(e);
  //     });
  // };

  return (
    <div
      style={{
        width: "300px",
        padding: "10px",
        marginRight: "10px",
        textAlign: "center",
      }}
    >
      <h3>Edit Meal</h3>
      <TextField
        id="outlined-textarea"
        label="Name"
        fullWidth
        placeholder="Placeholder"
        value={name}
        onChange={(e) => setName(e.target.value)}
        sx={{
          margin: "10px",
        }}
      />
      <TextField
        id="outlined-textarea"
        label="kCal"
        fullWidth
        placeholder="Placeholder"
        value={kcal}
        onChange={(e) => setKcal(e.target.value)}
        inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
        sx={{
          margin: "10px",
        }}
      />
      <TextField
        id="outlined-textarea"
        label="Notes"
        fullWidth
        placeholder="Placeholder"
        multiline
        value={notes}
        onChange={(e) => setNotes(e.target.value)}
        sx={{
          margin: "10px",
        }}
      />
      <>
        <Button
          variant="outlined"
          style={{ margin: "2px" }}
          startIcon={<AddIcon />}
          onClick={() =>
            props.save({
              id,
              name,
              kcal,
              notes,
            })
          }
        >
          Save
        </Button>
        <Button
          variant="outlined"
          color="warning"
          style={{ margin: "2px" }}
          startIcon={<CancelIcon />}
          onClick={props.cancel}
        >
          Cancel
        </Button>
        <Button
          variant="outlined"
          color="error"
          style={{ margin: "2px" }}
          startIcon={<DeleteIcon />}
          onClick={props.delete}
        >
          Delete
        </Button>
      </>
    </div>
  );
};

function AddMealPage() {
  const [searchParams, setSearchParams] = useSearchParams();

  const date = parseISO(searchParams.get("date") || "");
  const meals =
    useLiveQuery(async () => {
      return await db.meals.toArray();
    }, []) || [];

  const [draw, setDraw] = React.useState<Meal | null>(null);
  const navigate = useNavigate();
  const cancel = () =>
    navigate({
      pathname: "/",
      search: `?${createSearchParams({ date: date.toISOString() })}`,
    });
  const [query, setQuery] = React.useState("");
  const updateQuery = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value);
  };
  const header = (
    <>
      <p>Adding to {date.toDateString()}</p>
      <OutlinedInput
        startAdornment={
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        }
        sx={{
          margin: "4px",
        }}
        size="small"
        placeholder="Search"
        value={query}
        onChange={updateQuery}
      />
    </>
  );

  const options = { pre: "", post: "" };

  const matchedMeals = filter(
    query,
    meals.map((x) => x.name),
    options
  );
  const mealTable = matchedMeals.map((x) => meals[x.index]);
  const content = (
    <>
      <Table stickyHeader size="small">
        <colgroup>
          <col />
          <col style={{ width: "70%" }} />
          <col style={{ width: "30%" }} />
          <col />
        </colgroup>
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>Name</TableCell>
            <TableCell>kCal</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {mealTable.map((x) => (
            <TableRow key={x.id}>
              <TableCell>
                {menuAdd(() => {
                  db.journal.add({
                    date,
                    name: x.name,
                    kcal: x.kcal,
                  });
                  navigate({
                    pathname: "/",
                    search: `?${createSearchParams({
                      date: date.toISOString(),
                    })}`,
                  });
                })}
              </TableCell>
              <TableCell>{x.name}</TableCell>
              <TableCell>{x.kcal}</TableCell>
              <TableCell>
                {menuEdit(async () => {
                  setDraw((await db.meals.get(x.id || -1)) || null);
                })}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <SwipeableDrawer
        anchor="right"
        open={draw !== null}
        onClose={() => setDraw(null)}
        onOpen={() => setDraw(null)}
      >
        <AddMeal
          meal={
            draw || {
              id: undefined,
              name: "",
              notes: "",
              kcal: "",
            }
          }
          save={(meal) => {
            db.meals.put(meal);
            setDraw(null);
          }}
          cancel={() => setDraw(null)}
          delete={() => {
            db.meals.delete(draw?.id || -1);
            setDraw(null);
          }}
        />
      </SwipeableDrawer>
      {/* <TableContainer>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>Meal</TableCell>
              <TableCell align="right">Calories</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {mealTable.map((row) => (
              <TableRow
                key={row.name}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.name}
                </TableCell>
                <TableCell align="right">{row.calories}</TableCell>
                <TableCell align="right">{menuActions()}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer> */}
      {/* <DataTable value={mealTable} scrollable scrollHeight="flex">
        <Column
          field="meal"
          header="Item"
          style={{ whiteSpace: "nowrap" }}
        ></Column>
        <Column
          field="calories"
          header="Calories"
          align="right"
          style={{ width: "10px" }}
          alignFrozen="right"
          frozen
        ></Column>
        <Column
          header=""
          align="right"
          body={menuActions}
          style={{ whiteSpace: "nowrap", width: "10px" }}
          alignFrozen="right"
          frozen
        ></Column>
      </DataTable> */}
    </>
  );
  const footer = (
    <>
      <Toolbar
        center={
          <>
            <Button
              variant="outlined"
              style={{ margin: "2px" }}
              startIcon={<AddIcon />}
              onClick={async () =>
                setDraw({
                  id: (await db.meals.count()) + 1,
                  notes: "",
                  name: "",
                  kcal: "",
                })
              }
            >
              Create New Meal
            </Button>
            <Button
              variant="outlined"
              color="warning"
              style={{ margin: "2px" }}
              onClick={cancel}
              startIcon={<CancelIcon />}
            >
              Cancel
            </Button>
          </>
        }
      />
    </>
  );
  return (
    <Page header={header} content={content} footer={footer} title="Add Meal" />
  );
}

export default AddMealPage;
